// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  gradientColors: "#4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1",
  firstName: "Nanduri",
  middleName: "Mahathi Sai",
  lastName: "Nithin",
  message: " Passionate about changing the world. ",
  icons: [
    {
      image: "fa-github",
      url: "https://github.com/NITHIN1606",
    },

    {
      image: "fa-instagram",
      url: "https://www.instagram.com/nithin_1606/",
    },
    {
      image: "fa-linkedin",
      url: "linkedin.com/in/nanduri-mahathi-sai-nithin-239a53213",
    },
  ],
};

// ABOUT SECTION
// If you want the About Section to show a profile picture you can fill the profilePictureLink either with:
//a) your Instagram username
//      i.e:profilePictureLink:"johnDoe123",
//b) a link to an hosted image
//      i.e:profilePictureLink:"www.picturesonline.com/johnDoeFancyAvatar.jpg",
//c) image in "editable-stuff" directory and use require("") to import here,
//      i.e: profilePictureLink: require("../editable-stuff/hashirshoaeb.png"),
//d) If you do not want any picture to be displayed, just leave it empty :)
//      i.e: profilePictureLink: "",
// For Resume either provide link to your resume or import from "editable-stuff" directory
//     i.e resume: require("../editable-stuff/resume.pdf"),
//         resume: "https://docs.google.com/document/d/13_PWdhThMr6roxb-UFiJj4YAFOj8e_bv3Vx9UHQdyBQ/edit?usp=sharing",

const about = {
  show: true,
  heading: "About Me",
  imageLink: require("../editable-stuff/nithin12.jpeg"),
  imageSize: 375,
  message:
    "I am a pre-final year student at IIIT Naya Raipur and pursuing BTech in Data Science & Artificial Intelligence domain. I am interested in Machine Learning and Data Science and participated in various hackathons and competitions related to this field.",
  resume:
    "https://drive.google.com/file/d/1zbUDV4Da0G8xiegbnriCfPGN1PI-aYLO/view?usp=sharing",
};

// PROJECTS SECTION
// Setting up project lenght will automatically fetch your that number of recently updated projects, or you can set this field 0 to show none.
//      i.e: reposLength: 0,
// If you want to display specfic projects, add the repository names,
//      i.e ["repository-1", "repo-2"]
const repos = {
  show: true,
  heading: "Recent Projects",
  gitHubUsername: "NITHIN1606",
  reposLength: 4,
  specificRepos: [],
};

// Leadership SECTION
const leadership = {
  show: false,
  heading: "Leadership",
  message:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae auctor eu augue ut lectus arcu bibendum at varius. Libero justo laoreet sit amet cursus sit amet. Imperdiet dui accumsan sit amet nulla facilisi morbi. At auctor urna nunc id. Iaculis urna id volutpat lacus laoreet non curabitur gravida. Et magnis dis parturient montes nascetur ridiculus mus mauris. In nisl nisi scelerisque eu ultrices vitae auctor. Mattis nunc sed blandit libero volutpat sed cras ornare. Pulvinar neque laoreet suspendisse interdum consectetur libero.",
  images: [
    {
      img: require("../editable-stuff/nithin.jpeg"),
      label: "First slide label",
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
    },
    {
      img: require("../editable-stuff/nithin.jpeg"),
      label: "Second slide label",
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
    },
  ],
  imageSize: {
    width: "615",
    height: "450",
  },
};

// SKILLS SECTION
const skills = {
  show: true,
  heading: "Skills",
  hardSkills: [
    { name: "Python", value: 90 },
    { name: "SQL", value: 75 },
    { name: "Machine Learning", value: 85 },
    { name: "Computer Vision", value: 65 },
    { name: "Java", value: 90 },
    { name: "React", value: 65 },
    { name: "HTML/CSS", value: 55 },
    { name: "C#", value: 80 },
  ],
  softSkills: [
    { name: "Goal-Oriented", value: 80 },
    { name: "Collaboration", value: 90 },
    { name: "Positivity", value: 75 },
    { name: "Adaptability", value: 85 },
    { name: "Problem Solving", value: 75 },
    { name: "Empathy", value: 90 },
    { name: "Organization", value: 70 },
    { name: "Creativity", value: 90 },
  ],
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: "Get In Touch",
  message:
    "If you have any questions, or if you just want to say hi, please feel free to email me at",
  email: "nandurimahathisaintihin@gmail.com",
};

const experiences = {
  show: true,
  heading: "Experiences",
  data: [
    {
      role: "Project Management Intern", // Here Add Company Name
      companylogo: require("../assets/img/intern.jpg"),
      date: "Oct 2022 – Present",
    },
  ],
};
const certificates = {
  show: true,
  heading: "My Certificates",
  data: [
    {
      role: "IEEE Intenational Coference", // Here Add Company Name
      companylogo: require("../assets/img/ieee.png"),
      link: "https://drive.google.com/file/d/1n9BCCzW5REhDyJDJdU7ktqM3WN6qWxSZ/view",
    },
    {
      role: "Wadhwan Foundation NEN Program", // Here Add Company Name
      companylogo: require("../assets/img/wadhwani.jfif"),
      link: "https://drive.google.com/file/d/162NTs05JcQ2OZZ7rsaTyS3xNwdMWL5ro/view",
    },
    {
      role: "IEEE English For Technical Professionals", // Here Add Company Name
      companylogo: require("../assets/img/ieee.png"),
      link: "https://drive.google.com/file/d/1wKtMQeIwOHrclLCyBEwg1e3WmzYR5x6S/view",
    },
    {
      role: "Digital Marketing Training Program", // Here Add Company Name
      companylogo: require("../assets/img/invo.png"),
      link: "https://drive.google.com/file/d/17Q-P3kPdW1NxYNyciuyi8D6uH0oIpfQA/view",
    },
  ],
};

// Blog SECTION
// const blog = {
//   show: true,
// };

export {
  navBar,
  mainBody,
  about,
  repos,
  skills,
  leadership,
  getInTouch,
  experiences,
  certificates,
  // blog,
};
