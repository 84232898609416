import React from "react";
import ExperienceCard from "./ExperienceCard";
import { Jumbotron } from "./migration";
import infographic from "../../assets/img/infographic.jpg";
import { Container, Row, Col } from "react-bootstrap";
import "./styles.css";
const Infographic = ({ heading, experiences }) => {
  return (
    <section className="section">
      <Container>
        <Jumbotron id="infographic" className="bg-white">
          <h2 className="display-4 mb-5 text-center">Infographic</h2>
          {/* <Row style={{ justifyContent: "center" }}> */}
          <div
            className=""
            style={{
              overflowX: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={infographic}
              style={{ maxWidth: "800px", width: "80%" }}
              alt=""
              className=""
            />
          </div>
          {/* </Row> */}
        </Jumbotron>
      </Container>
    </section>
  );
};

export default Infographic;
