import React from "react";
import ExperienceCard from "./ExperienceCard";
import { Jumbotron } from "./migration";
import { Container, Row } from "react-bootstrap";
import { alignPropType } from "react-bootstrap/esm/types";

const Experience = ({ experiences }) => {
  return (
    <section className="section">
      <Container>
        <Jumbotron className="bg-white">
          <h2 className="display-4 mb-5 text-center">{experiences.heading}</h2>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="adjustPage"
          >
            {experiences.data.map((data, index) => {
              return <ExperienceCard key={index} data={data} />;
            })}

            <div
              style={{
                marginTop: "4rem",
                marginLeft: "2rem",
                textAlign: "justify",
              }}
            >
              <p>
                Saday is a Non-Governmental Organization registered under
                Charitable Trust Act by government of India. Aim of this
                organization is to providing assistance and aid programs for
                needy people, diminishing disparities between different
                societies and combating the challenges posed by COVID -19. My
                work here and an Product Manager Intern includes-
              </p>
              <ul>
                <li>
                  Determining what features the development team should build
                </li>
                <li>
                  Working with UI and UX to figure out how to streamline the
                  interface and overall user experience
                </li>
                <li>Ralling a team to turn that vision into a reality.</li>
              </ul>
            </div>
          </div>
        </Jumbotron>
      </Container>
    </section>
  );
};

export default Experience;
