import React from "react";
import ExperienceCard from "./ExperienceCard";
import { Jumbotron } from "./migration";
import { Container, Row } from "react-bootstrap";
import { alignPropType } from "react-bootstrap/esm/types";

const Certificates = ({ certificates }) => {
  return (
    <section className="section">
      <Container>
        <Jumbotron id="certificates" className="bg-white">
          <h2 className="display-4 mb-5 text-center">
            {certificates?.heading}
          </h2>

          <Row
            style={{
              rowGap: "2rem",
            }}
          >
            {certificates?.data.map((data, index) => {
              return (
                <ExperienceCard key={index} data={data} type="certificates" />
              );
            })}
          </Row>
        </Jumbotron>
      </Container>
    </section>
  );
};

export default Certificates;
