import React from "react";

import { Col } from "react-bootstrap";

const ExperienceCard = ({ data, type }) => {
  return (
    <Col lg="4">
      <div
        className="pb-5 text-center"
        style={{ boxShadow: " 0 4px 5px 3px rgba(119, 113, 136, 0.459)" }}
      >
        <img
          className=" bg-white mb-3"
          src={data.companylogo}
          alt=""
          style={{ maxWidth: "250px", maxHeight: "200px", objectFit: "cover" }}
        />
        <p className="lead">
          {data.role}
          <br />

          {type === "certificates" ? (
            <a
              href={data.link}
              style={
                {
                  // textDecoration: "none",
                  // border: "black 1px solid",
                  // padding: "0.2rem 0.4rem",
                  // borderRadius: "10px",
                }
              }
            >
              Link
            </a>
          ) : (
            data.date
          )}
        </p>
      </div>
    </Col>
  );
};

export default ExperienceCard;
