import React from "react";
// import ExperienceCard from "./ExperienceCard";
// import { Jumbotron } from "./migration";
import Hubli1 from "../../assets/img/Hubli1.jpeg";
import Hubli2 from "../../assets/img/Hubli2.jpeg";
import Hubli3 from "../../assets/img/Hubli3.jpeg";
import blog1 from "../../assets/img/blog1.jpg";
import blog2 from "../../assets/img/blog2.jpeg";
import blog3 from "../../assets/img/blog3.jpg";

import maredumilli1 from "../../assets/img/maredumilli1.jpeg";
import maredumilli2 from "../../assets/img/maredumilli2.jpeg";
import maredumilli3 from "../../assets/img/maredumilli3.jpeg";
// import { Container, Row, Col } from "react-bootstrap";
import "./styles.css";

const TravelBlogs = ({ heading, experiences }) => {
  return (
    <section className="section" id="blogs">
      <div className="container-lg mt-5 bg-blue">
        <h1 className="display-4 mb-5 text-center">My Space</h1>
        <div className="m-5">
          <div className="">
            <div className="">
              <div className="">
                <div className="">
                  <h1 className="">My Maredumilli Trip</h1>
                  <p className="lead1">
                    After a long time, I went on a trip to Maredumilli with my
                    family. It was a great experience. a mesmerizing green space
                    with a great experience of dr iving on Ghat Road. I have
                    visited different waterfalls, including: Rampa Waterfalls:
                    It is one of the best waterfalls in the East Godavari
                    district; the cascades from a height of 50 feet throughout
                    the year, and I personally feel that it is the best place
                    for families and friends. Jalatarangini Waterfalls: It also
                    has naturally formed rocks in different sizes and shapes,
                    and we took a lot of pictures in this area. Amruthadhara
                    Falls: Amruthadhara is formed over a seasonal stream amidst
                    forest and steep rocks. During the monsoon, this small
                    waterfall is in a full gush and would be heaven for nature
                    lovers. The waterfall is in two stages. This place is not so
                    comfortable to take a bath, but one can slide. One needs to
                    climb down a steep pathway of 1 km to reach the waterfalls
                    from the entrance.
                  </p>
                </div>
              </div>
              <div
                className="adjustPage"
                style={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                  justifyContent: "center",
                  overflowX: "auto",
                }}
              >
                <img
                  src={maredumilli1}
                  className="card-img"
                  alt="..."
                  style={{
                    width: "88%",
                    objectFit: "cover",
                    alignSelf: "center",
                    justifySelf: "center",
                    overflowX: "auto",
                    // maxHeight: "500px",
                  }}
                />
              </div>
              <div
                className="adjustPage"
                style={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "2rem",
                  overflowX: "auto",
                }}
              >
                <img
                  src={maredumilli2}
                  className="card-img"
                  alt="..."
                  style={{
                    width: "40%",
                    maxHeight: "500px",
                    objectFit: "cover",
                  }}
                />
                <img
                  src={maredumilli3}
                  className="card-img"
                  alt="..."
                  style={{ width: "50%", objectFit: "cover" }}
                />
              </div>
            </div>
            <hr />
          </div>
        </div>
        <div className="m-5">
          <div className="">
            <div className="row">
              <div className="">
                <div className="">
                  <h1 className="">My SSS Hubli Vacation</h1>
                  <p className="lead1">
                    A relaxing vacation with family is all we need to restore
                    peace of mind. One such experience for me was my trip to SSS
                    Hubli and the vacation spot of Utsav Rock Garden. A
                    combination of art, architecture, and nature in one
                    location, as well as one of India's and the world's finest
                    museums. It has the world's finest art gallery and art
                    museum that depict the village lifestyle and culture.
                  </p>
                </div>
              </div>
              <div
                className="adjustPage"
                style={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                  justifyContent: "center",
                  overflowX: "auto",
                }}
              >
                <img
                  src={Hubli1}
                  className="card-img"
                  alt="..."
                  style={{
                    width: "88%",
                    objectFit: "cover",
                    alignSelf: "center",
                    justifySelf: "center",
                    overflowX: "auto",
                    maxHeight: "500px",
                  }}
                />
              </div>
              <div
                className="adjustPage"
                style={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "2rem",
                  overflowX: "auto",
                  maxHeight: "450px",
                }}
              >
                <img
                  src={Hubli2}
                  className="card-img"
                  alt="..."
                  style={{
                    width: "40%",
                    maxHeight: "500px",
                    objectFit: "cover",
                  }}
                />
                <img
                  src={Hubli3}
                  className="card-img"
                  alt="..."
                  style={{ width: "50%", objectFit: "cover" }}
                />
              </div>
            </div>
            <hr />
          </div>
        </div>
        <div className="m-5">
          <div className="">
            <div className="row">
              <div className="">
                <div className="">
                  <h1 className="">Bernard Arnault: The journey before the 200 Billion</h1>
                  <div
                className="adjustPage"
                style={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                  justifyContent: "center",
                  overflowX: "auto",
                }}
              >
                <img
                  src={blog1}
                  className="card-img"
                  alt="..."
                  style={{
                    width: "88%",
                    objectFit: "cover",
                    alignSelf: "center",
                    justifySelf: "center",
                    overflowX: "auto",
                    maxHeight: "500px",
                    marginBottom:"2rem"
                  }}
                />
              </div>
                  <p className="lead1">
                    
                      Bernard Arnault is a French businessman and the CEO of LVMH, the world's largest luxury goods conglomerate. Arnault has been the driving force behind the success of LVMH and has played a significant role in shaping the luxury goods industry over the past few decades. In this blog, we will explore Arnault's career and his contributions to the growth of LVMH.
                      <br />
                      <br />
                      <strong>Early Career</strong>
                      <br />

                      Arnault was born in 1949 in Roubaix, France. He studied engineering at the École Polytechnique in Paris and later attended the École des Hautes Études Commerciales de Paris (HEC) for business studies. After completing his education, Arnault joined his father's construction company, Ferret-Savinel. However, he soon left the family business to pursue a career in finance.
                      <br />
                      In 1984, Arnault purchased the bankrupt textile company Boussac Saint-Frères, which owned several luxury brands, including Christian Dior. He restructured the company and merged it with LVMH, which was formed in 1987 after the merger of Moët et Chandon and Hennessy. Arnault became the CEO of LVMH in 1989 and has been at the helm ever since.

                      <br />
                      <br />
                      <strong>Contributions to LVMH</strong>
                      <br />
                      

                      Under Arnault's leadership, LVMH has grown into a global powerhouse in the luxury goods industry. The company owns over 70 luxury brands, including Louis Vuitton, Fendi, Dior, Givenchy, and Bulgari. LVMH has a presence in over 70 countries and has a market capitalization of over €250 billion.

                      Arnault's strategy for growing LVMH has been to acquire successful luxury brands and to invest in the development of new brands. He has also focused on expanding the company's presence in emerging markets, particularly in Asia. LVMH has invested heavily in digital technology to enhance the customer experience and to reach new customers.

                      Arnault's impact on the luxury goods industry has been significant. He has been a driving force behind the trend towards consolidation in the industry, with LVMH acquiring several of its competitors over the years. Arnault has also been an advocate for sustainable and ethical practices in the industry and has been instrumental in establishing LVMH's sustainability program.

                      <br />
                      <br />
                      <strong>Personal Wealth</strong>
                      <br />
                      

                      Arnault's success at LVMH has made him one of the wealthiest people in the world. As of 2021, his net worth is estimated to be over $150 billion, making him the third-richest person in the world, according to Forbes. Arnault's personal wealth has been largely driven by his ownership of LVMH, which is one of the most valuable companies in the world.
                      
                      <br />
                      <br />
                      <strong> Conclusion</strong>
                      <br />

                      Bernard Arnault's contributions to the growth of LVMH and the luxury goods industry have been significant. His leadership and vision have transformed LVMH into a global powerhouse and have set the standard for the luxury goods industry. Arnault's focus on innovation, sustainability, and ethical practices has positioned LVMH for continued success in the future. As Arnault continues to lead LVMH, it is likely that the company will remain at the forefront of the luxury goods industry for years to come.
                  </p>
                </div>
              </div>
              
             
            </div>
            <hr />
          </div>
        </div>
        <div className="m-5">
          <div className="">
            <div className="row">
              <div className="">
                <div className="">
                  <h1 className="">Investment Banking Firms</h1>
                  <div
                className="adjustPage"
                style={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                  justifyContent: "center",
                  overflowX: "auto",
                }}
              >
                <img
                  src={blog2}
                  className="card-img"
                  alt="..."
                  style={{
                    width: "88%",
                    // objectFit: "overflow",
                    alignSelf: "center",
                    justifySelf: "center",
                    overflowX: "auto",
                    maxHeight: "500px",
                    marginBottom:"2rem"
                  }}
                />
              </div>
                  <p className="lead1">
                    
                  Investment banking is a specialized area of finance that involves helping companies and governments raise capital by underwriting and issuing securities, providing financial advisory services, and facilitating mergers and acquisitions. The investment banking industry is dominated by a few large firms that operate globally and have significant influence on the financial markets. In this blog, we will explore some of the major investment banking firms in the world.
                      <br />
                      <br />
                      <strong>JPMorgan Chase</strong>
                      <br />

                      JPMorgan Chase is one of the largest investment banks in the world, with a presence in over 100 countries. The firm offers a range of investment banking services, including underwriting and issuing securities, providing financial advisory services, and facilitating mergers and acquisitions. JPMorgan Chase has a strong reputation for innovation and has been at the forefront of developing new financial products and services.

                      <br />
                      <br />
                      <strong>Goldman Sachs</strong>
                      <br />
                      
                      Goldman Sachs is another major player in the investment banking industry, with a reputation for being one of the most prestigious and exclusive firms on Wall Street. The firm offers a range of investment banking services, including underwriting and issuing securities, providing financial advisory services, and facilitating mergers and acquisitions. Goldman Sachs is known for its high-profile clients and has been involved in some of the biggest deals in history.

                      <br />
                      <br />
                      <strong>Morgan Stanley</strong>
                      <br />
                      

                      Morgan Stanley is a leading investment bank that provides a wide range of financial services to corporations, governments, institutions, and individuals around the world. The firm's investment banking division offers underwriting and issuance of securities, financial advisory services, and M&A advisory services. Morgan Stanley is known for its expertise in the technology sector and has played a key role in many of the industry's most significant deals.
                      
                      <br />
                      <br />
                      <strong> Citigroup</strong>
                      <br />

                                            
                      Citigroup is a global investment bank that operates in over 160 countries. The firm offers a wide range of investment banking services, including underwriting and issuance of securities, financial advisory services, and M&A advisory services. Citigroup has a strong presence in emerging markets and has been at the forefront of developing new financial products and services to meet the needs of its clients.
                      <br />
                      <br />
                      <strong> Barclays</strong>
                      <br />

                      Barclays is a global investment bank that provides a wide range of financial services, including investment banking, wealth management, and retail banking. The firm's investment banking division offers underwriting and issuance of securities, financial advisory services, and M&A advisory services. Barclays is known for its expertise in the healthcare and energy sectors and has been involved in some of the largest deals in these industries.
                      <br />
                      <br />
                      <strong> Credit Suisse</strong>
                      <br />

                     
                      Credit Suisse is a leading investment bank with a strong presence in Europe and a growing presence in Asia and the United States. The firm offers a range of investment banking services, including underwriting and issuance of securities, financial advisory services, and M&A advisory services. Credit Suisse has a strong reputation for its expertise in the commodities market and has been involved in some of the largest commodity deals in history.
                      <br />
                      <br />
                      <strong> Deutsche Bank</strong>
                      <br />
                        Deutsche Bank is a leading investment bank with a strong presence in Europe and a growing presence in the United States and Asia. The firm offers a range of investment banking services, including underwriting and issuance of securities, financial advisory services, and M&A advisory services. Deutsche Bank has a strong reputation for its expertise in the fixed income market and has been involved in some of the largest debt issuances in history.


                      <br />
                      <br />
                      <strong>Conclusion</strong>
                      <br />

                      The investment banking industry is dominated by a few large firms that operate globally and have significant influence on the financial markets. JPMorgan Chase, Goldman Sachs, Morgan Stanley, Citigroup, Deutsche Bank, Barclays, and Credit Suisse are some of the major investment banking firms in the world. These firms offer a range of investment banking services, including underwriting and issuance of securities, financial advisory services, and M&A advisory services. Investors looking to invest in the investment banking industry should consider these
                  </p>
                </div>
              </div>
              
             
            </div>
            <hr />
          </div>
        </div>
        <div className="m-5">
          <div className="">
            <div className="row">
              <div className="">
                <div className="">
                  <h1 className="">BlackRock Inc VS The Vanguard Group</h1>
                  <div
                className="adjustPage"
                style={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                  justifyContent: "center",
                  overflowX: "auto",
                }}
              >
                <img
                  src={blog3}
                  className="card-img"
                  alt="..."
                  style={{
                    width: "88%",
                    // objectFit: "overflow",
                    alignSelf: "center",
                    justifySelf: "center",
                    overflowX: "auto",
                    maxHeight: "500px",
                    marginBottom:"2rem"
                  }}
                />
              </div>
                  <p className="lead1">
                    
                  BlackRock Inc. and The Vanguard Group are two of the largest and most influential investment management companies in the world. Both companies manage trillions of dollars in assets and offer a range of investment products and services to individual and institutional investors. Despite their similarities, however, BlackRock and Vanguard have distinct investment philosophies and approaches that set them apart from one another.
                      <br />
                      <br />
                      <strong>BlackRock Inc.</strong>
                      <br />

                      BlackRock Inc. is a global investment management company that was founded in 1988. With over $9 trillion in assets under management, BlackRock is the largest asset manager in the world. The company offers a range of investment products and services, including mutual funds, exchange-traded funds (ETFs), and separately managed accounts.
                      <br />
                      BlackRock's investment philosophy is based on a commitment to diversification and risk management. The company believes that by investing in a broad range of assets and geographies, it can reduce the risk of losses and generate consistent returns over the long term. BlackRock is also known for its active management approach, which involves actively selecting and managing investments rather than simply tracking a benchmark index.

                      <br />
                      <br />
                      <strong>The Vanguard Group</strong>
                      <br />
                      
                      The Vanguard Group is an investment management company that was founded in 1975. With over $7 trillion in assets under management, Vanguard is the second-largest asset manager in the world. The company is known for its low-cost index funds, which are designed to track a benchmark index and provide investors with exposure to a broad range of assets.
                    <br />
                      Vanguard's investment philosophy is based on a commitment to passive investing and low costs. The company believes that by tracking a benchmark index, it can provide investors with broad market exposure at a low cost. Vanguard is also known for its investor-owned structure, which means that the company is owned by its mutual fund shareholders.

                      <br />
                      <br />
                      <strong>Comparison of BlackRock and Vanguard</strong>
                      <br />
                      

                      While both BlackRock and Vanguard are major players in the global investment management industry, there are some key differences between the two companies. Here are a few of the main points of comparison:

                      Investment Philosophy - BlackRock is known for its active management approach, while Vanguard is known for its passive investing approach.

                      Product Offering - BlackRock offers a range of investment products and services, including mutual funds, ETFs, and separately managed accounts. Vanguard is primarily known for its low-cost index funds.

                      Asset Under Management - BlackRock is the largest asset manager in the world, with over $9 trillion in assets under management. Vanguard is the second-largest asset manager in the world, with over $7 trillion in assets under management.

                      Fees - BlackRock's fees tend to be higher than Vanguard's, due in part to the company's active management approach. Vanguard's low-cost index funds, on the other hand, tend to have some of the lowest fees in the industry.
                      
                      <br />
                      <br />
                      <strong> Which One is Better?</strong>
                      <br />
                      Deciding which company is better, BlackRock or Vanguard, ultimately depends on the individual investor's goals and preferences. BlackRock's active management approach may be appealing to investors who are looking for more targeted investment strategies and are willing to pay higher fees. Vanguard's low-cost index funds, on the other hand, may be more attractive to investors who are looking for broad market exposure at a low cost.
                      <br />
                      In conclusion, both BlackRock and Vanguard are highly respected investment management companies with distinct investment philosophies and approaches. By understanding the differences between these two industry leaders, investors can make more informed decisions about which company and investment products best meet their individual needs and preferences.
                      
                  </p>
                </div>
              </div>
              
             
            </div>
            <hr />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TravelBlogs;
